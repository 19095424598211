import StarOutlineOutlined from '@mui/icons-material/StarOutlineOutlined'
import AdsClickOutlined from '@mui/icons-material/AdsClickOutlined'
import HomeMaxOutlined from '@mui/icons-material/HomeMaxOutlined'
import {lazy} from 'react'
import { Route } from 'react-router-dom'
import LinkOutlined from '@mui/icons-material/LinkOutlined'
import HistoryEduOutlined from '@mui/icons-material/HistoryEduOutlined'
import ShareOutlined from '@mui/icons-material/ShareOutlined'
import RssFeedOutlined from '@mui/icons-material/RssFeedOutlined'
import NotesOutlined from '@mui/icons-material/NotesOutlined'
import ImageOutlined from '@mui/icons-material/ImageOutlined'
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';

const Home = lazy(() => import('pages/home'))
const Ranking = lazy(() => import('pages/ranking'))
const NaverTraffic = lazy(() => import('pages/traffic'))
const BlogAccountManager = lazy(() => import('pages/blogAcctManager'))
const BlogAccountReport = lazy(() => import('pages/blogAcctReport'))
const PostsManager = lazy(() => import('pages/postsManager'))
const PostView = lazy(() => import('pages/postsManager/PostView'))
const AddImage = lazy(() => import('pages/post-image'))
const BackLink = lazy(() => import("pages/backlink"))
const Preset = lazy(() => import("pages/preset"))
const WebsiteSeoBacklink = lazy(() => import("pages/seoandbacklink"))
const WebsiteSocialSignal = lazy(() => import("pages/websitesocialsignal"))

const routes = [
  {
    subheader: "",
    items: [
      {
        label: "Home",
        href: "/home",
        component: Home,
        icon: <HomeMaxOutlined />
      }
    ]
  },
  {
    subheader: "Auto Write",
    items: [
      {
        label: "Auto Write Post",
        href: "/auto-write-post",
        icon: <HistoryEduOutlined />,
        component: PostsManager
      },
      {
        label: "Add Post",
        href: "/auto-write-post/add-post",
        icon: <HistoryEduOutlined />,
        component: PostView,
        hidden: true
      },
      {
        label: "Daily Auto Write",
        href: "/daily-auto-write",
        icon: <HistoryEduOutlined />,
        component: lazy(() => import('pages/dailyAutoWrite'))
      },
      {
        label: "Add Images",
        href: "/auto-write-add-images",
        icon: <ImageOutlined />,
        component: AddImage
      },
      {
        label: "AutoWrite Report",
        href: "/report-manger-auto-write",
        icon: <RssFeedOutlined />,
        component: BlogAccountReport
      }
    ]
  },
  {
    subheader: "Settings",
    items: [
      {
        label: "Preset",
        href: "/preset",
        icon: <NotesOutlined />,
        component: Preset
      },
      {
        label: "AutoWrite Manager",
        href: "/account-manger-auto-write",
        icon: <RssFeedOutlined />,
        component: BlogAccountManager
      }
    ]
  },
  {
    subheader: "Ranking Check",
    items: [
      {
        label: "Naver Ranking",
        href: "/ranking-check",
        component: Ranking,
        icon: <StarOutlineOutlined />
      }
    ]
  },
  {
    subheader: "Traffic Services",
    items: [
      {
        label: "Naver Traffic",
        href: "/traffic-services",
        icon: <AdsClickOutlined />,
        component: NaverTraffic
      }
    ]
  },
  {
    subheader: "Backlink",
    items: [
      {
        label: "Backlink Service",
        href: "/backlink-service",
        icon: <LinkOutlined />,
        component: BackLink
      },
      {
        label: "Backlink Report",
        href: "/backlink-report",
        icon: <ShareOutlined/>,
        component: lazy(() => import('pages/backlink-report'))
      }
    ]
  },
  {
    subheader: "Services",
    items: [
      {
        label: "Website SEO & Backlinks",
        href: "/website-seo-backlink",
        component: WebsiteSeoBacklink,
        icon: <LinkOutlined />
      },
      {
        label: "Website Social Signal",
        href: "/website-social-signal",
        component: WebsiteSocialSignal,
        icon: <ConnectWithoutContactIcon />
      }
    ]
  },
]

function getDomRoutes() {
  const domRoutes = [] 
  routes.forEach(group => {
    if(!group.items) {
      return
    }
    group.items.forEach(item => {
      if(!item.component) {
        return
      }
      domRoutes.push(<Route path={item.href} key={item.href} Component={item.component}/>)
    })
  })
  return domRoutes
}

export {getDomRoutes}
export default routes